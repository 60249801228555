import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TipButton from "../../util/TipButton";
import PostEvent from "../event/PostEvent";
import Notifications from "./Notifications";
import AppLogo from "../../images/spaartyicon.png";
import AppTitle from "../../images/spaartylogo.png";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/ToolBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

// Icons
import Assignment from "@material-ui/icons/Assignment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PublicIcon from "@material-ui/icons/Public";
import KeyboardReturn from "@material-ui/icons/KeyboardReturn";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/userActions";

class Navbar extends Component {
  state = {
    anchorEl: null
  };
  handleOpen = event => {
    this.setState({ anchorEl: event.target });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  onMenuOpened = () => {};
  handleLogout = () => {
    this.handleClose();
    this.props.logoutUser();
  };
  render() {
    const anchorEl = this.state.anchorEl;
    const { authenticated } = this.props;
    let moreMenuMarkup = authenticated ? (
      <MenuItem key={0} onClick={this.handleLogout}>
        <KeyboardReturn />
        <Typography color="default" variant="body1">
          Log out
        </Typography>
      </MenuItem>
    ) : (
      <Fragment>
        <MenuItem key={0} onClick={this.handleClose}>
          <Person />
          <Typography
            component={Link}
            to="/login"
            color="default"
            variant="body1"
          >
            Login
          </Typography>
        </MenuItem>
        <MenuItem key={1} onClick={this.handleClose}>
          <PersonAdd />
          <Typography
            component={Link}
            to="/signup"
            color="default"
            variant="body1"
          >
            Sign up
          </Typography>
        </MenuItem>
      </Fragment>
    );
    return (
      <AppBar>
        <Toolbar className="nav-container">
          <div className="nav-logo">
            <Link to="/">
              <img src={AppLogo} alt="Spaarty" className="logo" />
            </Link>
          </div>
          <Hidden smDown>
            <div className="nav-logo">
              <Link to="/">
                <img src={AppTitle} alt="Spaarty" className="logo2" />
              </Link>
            </div>
          </Hidden>
          {authenticated ? (
            <div className="nav-buttons">
              <PostEvent />
              <Link to="/">
                <TipButton tip="Public events">
                  <PublicIcon />
                </TipButton>
              </Link>
              <Link to="/myevents">
                <TipButton tip="My events">
                  <Assignment />
                </TipButton>
              </Link>
              <Notifications />
              <TipButton tip="" onClick={this.handleOpen}>
                <ExpandMoreIcon />
              </TipButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                onEntered={this.onMenuOpened}
              >
                {moreMenuMarkup}
              </Menu>
            </div>
          ) : (
            <div className="nav-buttons">
              <Link to="/">
                <TipButton tip="Public events">
                  <PublicIcon />
                </TipButton>
              </Link>
              <TipButton tip="" onClick={this.handleOpen}>
                <ExpandMoreIcon />
              </TipButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                onEntered={this.onMenuOpened}
              >
                {moreMenuMarkup}
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  authenticated: state.user.authenticated
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Navbar);
