import React, { Component } from "react";
import PropTypes from "prop-types";

//Components
import PublicEvent from "../components/event/PublicEvent";
import Profile from "../components/profile/Profile";
import EventSkeleton from "../util/EventSkeleton";

//MUI
import Grid from "@material-ui/core/Grid";

// Redux
import { connect } from "react-redux";
import { getEvents } from "../redux/actions/dataActions";

class home extends Component {
  componentDidMount() {
    this.props.getEvents();
  }
  render() {
    const { events, loading } = this.props.data;
    let recentEventsMarkup = !loading ? (
      events.map(event => <PublicEvent event={event} key={event.eventId} />)
    ) : (
      <EventSkeleton />
    );
    return (
      <Grid container spacing={2}>
        <Grid item sm={9} xs={12}>
          {recentEventsMarkup}
        </Grid>
        <Grid item sm={3} xs={12}>
          <Profile />
        </Grid>
      </Grid>
    );
  }
}

home.propTypes = {
  getEvents: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  data: state.data
});

export default connect(
  mapStateToProps,
  { getEvents }
)(home);
