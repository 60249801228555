import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// MUI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// Icons

const styles = theme => ({
  ...theme.theme,
  root: {
    width: "100%"
  },
  heading: {
    backgroundColor: "#3f51b5",
    color: "#FFF",
    margin: "auto",
    padding: 20
  },
  resultsPaper: {
    padding: 20
  },
  tableHeader: {
    backgroundColor: "#333",
    color: "#FFF",
    margin: 0
  },
  tableBright: {
    margin: 0
  },
  tableDark: {
    margin: 0,
    backgroundColor: "#EEE"
  }
});

class Results extends Component {
  timeString = millis => {
    var ms = millis % 1000;
    var s = ((millis - ms) / 1000) % 60;
    var m = ((millis - ms - s * 1000) / 60000) % 60;
    var h = (millis - ms - s * 1000 - m * 60000) / 3600000;

    ms = Math.floor(ms / 100);
    s = s.toString().length === 1 ? "0" + s.toString() : s.toString();
    m = m.toString().length === 1 ? "0" + m.toString() : m.toString();

    if (h === 0) {
      return `${m}:${s}.${ms}`;
    }

    return `${h}:${m}:${s}.${ms}`;
  };

  render() {
    const { classes, contestants, cls } = this.props;

    return (
      <Grid container direction="column" spacing={2} className={classes.root}>
        {cls.map((cl, index) => {
          if (
            contestants.filter(
              contestant =>
                contestant.classId === cl.classId && contestant.finalized
            ).length > 0
          ) {
            return (
              <Grid item xs={12} key={index}>
                <Paper className={classes.resultsPaper}>
                  <Grid container direction="column" key={index}>
                    <Grid item xs={12}>
                      <div className={classes.heading}>{cl.name}</div>
                    </Grid>
                    <Grid item container direction="column" xs={12}>
                      <Grid
                        item
                        container
                        direction="row"
                        xs={12}
                        spacing={2}
                        key={index}
                        className={classes.tableHeader}
                      >
                        <Grid item xs={2}>
                          #
                        </Grid>
                        <Grid item xs={7}>
                          Navn
                        </Grid>
                        <Grid item xs={3}>
                          Tid
                        </Grid>
                      </Grid>
                      {contestants
                        .filter(
                          contestant =>
                            contestant.classId === cl.classId &&
                            contestant.finalized
                        )
                        .sort((a, b) => a.finalTime - b.finalTime)
                        .map((contestant, index) => {
                          return (
                            <Grid
                              item
                              container
                              direction="row"
                              xs={12}
                              spacing={2}
                              key={index}
                              className={
                                index % 2 === 0
                                  ? classes.tableBright
                                  : classes.tableDark
                              }
                            >
                              <Grid item xs={2}>
                                {index + 1}
                              </Grid>
                              <Grid item xs={7}>
                                <span>
                                  {contestant.firstName} {contestant.lastName}
                                </span>
                              </Grid>
                              <Grid item xs={3}>
                                {this.timeString(contestant.finalTime)}
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    );
  }
}

Results.propTypes = {
  contestants: PropTypes.array.isRequired,
  cls: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Results);
