import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TipButton from "../../util/TipButton";

// MUI
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

// Redux
import { connect } from "react-redux";
import { addContestant, clearErrors } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10
  },
  progressSpinner: {
    position: "absolute",
    left: "50%"
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15
  },
  addButton: {
    position: "fixed",
    right: 10,
    bottom: 10,
    zIndex: 1099
  },
  icon: {
    color: "white"
  },
  contestantNumber: {
    margin: "20px 20px 10px 0px",
    width: 200
  },
  selector: {
    margin: "20px 20px 10px 0px",
    float: "",
    width: 200
  }
});

class AddContestant extends Component {
  state = {
    open: false,
    firstName: "",
    lastName: "",
    runnerId: "",
    classId: "",
    telephone: "",
    errors: {}
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({
        firstName: "",
        lastName: "",
        telephone: "",
        runnerId: "",
        open: false,
        errors: {}
      });
    }
  }
  handleOpen = () => {
    this.setState({
      open: true
    });
    if (this.state.classId === "") {
      if (
        this.props.eventDetails.classes &&
        this.props.eventDetails.classes.length > 0
      ) {
        this.setState({
          classId: this.props.eventDetails.classes[0].classId
        });
      }
    }
  };
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {} });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.addContestant(this.props.eventDetails.eventId, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      runnerId: this.state.runnerId,
      classId: this.state.classId,
      telephone: this.state.telephone
    });
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      eventDetails,
      UI: { loading }
    } = this.props;

    const classSelectMarkup =
      eventDetails.classes && eventDetails.classes.length > 0 ? (
        eventDetails.classes.map((cl, index) => {
          const { classId, name } = cl;
          return (
            <MenuItem
              key={index}
              value={classId}
              onClick={this.handleClose}
              name={name}
            >
              <Typography color="textPrimary" variant="body1">
                {name}
              </Typography>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem onClick={this.handleClose}>
          There are no classes yet.
        </MenuItem>
      );

    return (
      <Fragment>
        <Tooltip title="Add a contestant" placement="top">
          <Fab
            color="secondary"
            className={classes.addButton}
            onClick={this.handleOpen}
            variant="extended"
          >
            <AddIcon className={classes.icon} />
            Add Contestant
          </Fab>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <TipButton
            tip="Close"
            onClick={this.handleClose}
            btnClassName={classes.closeButton}
          >
            <CloseIcon />
          </TipButton>
          <DialogTitle>Add a new contestant</DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <TextField
                name="runnerId"
                value={this.state.runnerId}
                type="text"
                label="Contestant Number"
                placeholder="Number or Id"
                error={errors.runnerId ? true : false}
                helperText={errors.runnerId}
                className={classes.contestantNumber}
                onChange={this.handleChange}
              />
              <FormControl className={classes.selector}>
                <InputLabel>Class</InputLabel>
                <Select
                  value={this.state.classId}
                  onChange={this.handleChange}
                  displayEmpty
                  name="classId"
                >
                  {classSelectMarkup}
                </Select>
              </FormControl>
              <TextField
                name="firstName"
                type="text"
                label="First name"
                placeholder="Contestants first name"
                error={errors.firstName ? true : false}
                helperText={errors.firstName}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="lastName"
                type="text"
                label="Last name"
                placeholder="Contestants last name"
                error={errors.lastName ? true : false}
                helperText={errors.lastName}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="telephone"
                type="telephone"
                label="Telephone number"
                placeholder="+47 12345678"
                error={errors.telephone ? true : false}
                helperText={errors.telephone}
                className={classes.shortTextField}
                onChange={this.handleChange}
              />
              <hr className={classes.invisibleSeperator} />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submitButton}
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

AddContestant.propTypes = {
  addContestant: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { addContestant, clearErrors }
)(withStyles(styles)(AddContestant));
