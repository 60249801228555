import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

//Components
import MyEvent from "../components/event/MyEvent";
import EventSkeleton from "../util/EventSkeleton";

//MUI
import Grid from "@material-ui/core/Grid";

// Redux
import { connect } from "react-redux";
import { getUserData } from "../redux/actions/userActions";

const styles = theme => ({
  ...theme.theme
});

class myEvents extends Component {
  componentDidMount() {
    this.props.getUserData();
  }
  render() {
    const {
      user: { events, loading }
    } = this.props;

    let recentEventsMarkup = !loading ? (
      events.map(event => <MyEvent event={event} key={event.eventId} />)
    ) : (
      <EventSkeleton />
    );
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {recentEventsMarkup}
        </Grid>
      </Grid>
    );
  }
}

myEvents.propTypes = {
  getUserData: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  data: state.data
});

export default connect(
  mapStateToProps,
  { getUserData }
)(withStyles(styles)(myEvents));
