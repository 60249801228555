import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TipButton from "../../util/TipButton";
import DateFnsUtils from "@date-io/date-fns";

// MUI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";

// Icons
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PublicIcon from "@material-ui/icons/Public";

// Redux
import { connect } from "react-redux";
import { postEvent, clearErrors } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10
  },
  progressSpinner: {
    position: "absolute"
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15
  }
});

class PostEvent extends Component {
  state = {
    body: "",
    title: "",
    dateTime: new Date(),
    location: "",
    published: false,
    open: false,
    errors: {}
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({
        body: "",
        title: "",
        dateTime: new Date(),
        location: "",
        published: false,
        open: false,
        errors: {}
      });
    }
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {} });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSwitchChange = event => {
    this.setState({ published: event.target.checked });
  };
  handleDateTimeChange = dateTime => {
    this.setState({ dateTime: dateTime });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.postEvent({
      title: this.state.title,
      body: this.state.body,
      dateTime: this.state.dateTime,
      location: this.state.location,
      published: this.state.published
    });
  };
  render() {
    const { errors, dateTime, published } = this.state;
    const {
      classes,
      UI: { loading }
    } = this.props;
    return (
      <Fragment>
        <TipButton tip="Post an event" onClick={this.handleOpen}>
          <AddIcon />
        </TipButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <TipButton
            tip="Close"
            onClick={this.handleClose}
            btnClassName={classes.closeButton}
          >
            <CloseIcon />
          </TipButton>
          <DialogTitle>Post a new event</DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <TextField
                name="title"
                type="text"
                label="Event title"
                placeholder="The name of the event"
                error={errors.title ? true : false}
                helperText={errors.title}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="location"
                type="text"
                label="Event location"
                placeholder="The location of the event"
                error={errors.location ? true : false}
                helperText={errors.location}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      ampm={false}
                      format="HH:mm dd/MM/yyyy"
                      margin="normal"
                      name="dateTime"
                      label="Event date and time"
                      error={errors.dateTime ? true : false}
                      helperText={errors.dateTime}
                      value={dateTime}
                      onChange={this.handleDateTimeChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <TipButton tip="Public">
                    <PublicIcon />
                  </TipButton>
                  Public
                  <Switch
                    name="published"
                    checked={published}
                    onChange={this.handleSwitchChange}
                    value="published"
                    color="secondary"
                  />
                </Grid>
              </Grid>
              <TextField
                name="body"
                type="text"
                label="Event description"
                multiline
                rows="3"
                placeholder="A descriptive event text"
                error={errors.body ? true : false}
                helperText={errors.body}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submitButton}
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

PostEvent.propTypes = {
  postEvent: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { postEvent, clearErrors }
)(withStyles(styles)(PostEvent));
