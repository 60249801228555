import {
  SET_EVENTS,
  SET_EVENT,
  LIKE_EVENT,
  UNLIKE_EVENT,
  LOADING_DATA,
  DELETE_EVENT,
  POST_EVENT,
  UPDATE_EVENT,
  SUBMIT_COMMENT,
  MAKE_EVENT_PUBLIC,
  MAKE_EVENT_NOT_PUBLIC,
  ADD_CLASS,
  ADD_CONTESTANT,
  DELETE_CONTESTANT,
  UPDATE_CONTESTANT,
  INC_RESULTS,
  DEC_RESULTS
} from "../types";

const initialState = {
  events: [],
  eventDetails: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false
      };
    case SET_EVENT:
      return {
        ...state,
        eventDetails: action.payload
      };
    case MAKE_EVENT_PUBLIC:
    case MAKE_EVENT_NOT_PUBLIC:
      let pubIndex = state.events.findIndex(
        event => event.eventId === action.payload.eventId
      );
      state.events[pubIndex] = action.payload;
      if (state.eventDetails.eventId === action.payload.eventId) {
        state.eventDetails = { ...state.eventDetails, ...action.payload };
      }
      return {
        ...state
      };
    case LIKE_EVENT:
    case UNLIKE_EVENT:
      let index = state.events.findIndex(
        event => event.eventId === action.payload.eventId
      );
      state.events[index] = action.payload;
      if (state.eventDetails.eventId === action.payload.eventId) {
        state.eventDetails = {
          ...state.eventDetails,
          ...action.payload
        };
      }
      return {
        ...state
      };
    case SUBMIT_COMMENT:
      let comIndex = state.events.findIndex(
        event => event.eventId === action.payload.eventId
      );
      if (comIndex >= 0) {
        let newCommentCount = state.events[comIndex].commentCount + 1;
        state.events[comIndex] = {
          ...state.events[comIndex],
          commentCount: newCommentCount
        };
      }
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          commentCount: state.eventDetails.commentCount + 1,
          comments: [...state.eventDetails.comments, action.payload]
        }
      };
    case DELETE_EVENT:
      let delIndex = state.events.findIndex(
        event => event.eventId === action.payload
      );
      if (delIndex >= 0) state.events.splice(delIndex, 1);
      return {
        ...state
      };
    case POST_EVENT:
      if (action.payload.published) {
        state.events = [action.payload, ...state.events];
      }
      return {
        ...state
      };
    case UPDATE_EVENT:
      if (action.payload.published) {
        let evIndex = state.events.findIndex(
          event => event.eventId === action.payload.eventId
        );

        state.events[evIndex] = action.payload;
      }
      return {
        ...state
      };
    case ADD_CLASS:
      let classIndex = state.eventDetails.classes.findIndex(
        cl => cl.name >= action.payload.name
      );
      if (classIndex < 0) {
        state.eventDetails.classes = [
          ...state.eventDetails.classes,
          action.payload
        ];
      } else {
        state.eventDetails.classes.splice(classIndex, 0, action.payload);
      }
      return {
        ...state
      };
    case ADD_CONTESTANT:
      let contIndex = state.eventDetails.contestants.findIndex(
        cont => cont.runnerId >= action.payload.runnerId
      );
      if (contIndex === -1) {
        state.eventDetails.contestants = [
          ...state.eventDetails.contestants,
          action.payload
        ];
      } else {
        state.eventDetails.contestants.splice(contIndex, 0, action.payload);
      }
      return {
        ...state
      };
    case DELETE_CONTESTANT:
      let delContIndex = state.eventDetails.contestants.findIndex(
        cont => cont.contestantId === action.payload
      );
      if (delContIndex >= 0)
        state.eventDetails.contestants.splice(delContIndex, 1);
      return {
        ...state
      };
    case UPDATE_CONTESTANT:
      let contUpdIndex = state.eventDetails.contestants.findIndex(
        cont => cont.contestantId === action.payload.contestantId
      );

      state.eventDetails.contestants[contUpdIndex] = {
        ...state.eventDetails.contestants[contUpdIndex],
        ...action.payload
      };
      return {
        ...state
      };
    case INC_RESULTS:
      let resIndex = state.events.findIndex(
        event => event.eventId === action.payload
      );
      state.events[resIndex].runCount++;
      if (state.eventDetails.eventId === action.payload) {
        state.eventDetails.runCount++;
      }
      return {
        ...state
      };
    case DEC_RESULTS:
      let decResIndex = state.events.findIndex(
        event => event.eventId === action.payload
      );
      state.events[decResIndex].runCount--;
      if (state.eventDetails.eventId === action.payload) {
        state.eventDetails.runCount--;
      }
      return {
        ...state
      };
    default:
      return state;
  }
}
