import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  ...theme.theme,
  commentImageDiv: {
    width: 90,
    height: 90,
    margin: "auto"
  },
  commentImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    margin: "auto",
    borderRadius: 5
  },
  commentData: {
    margin: "5px auto 5px 10px"
  }
});

class Comments extends Component {
  render() {
    const { comments, classes } = this.props;
    return (
      <Grid container>
        {comments.map((comment, index) => {
          const { body, createdAt, userImage, userHandle } = comment;
          return (
            <Fragment key={createdAt}>
              <hr
                className={
                  index === 0
                    ? classes.invisibleSeperator
                    : classes.visibleSeperator
                }
              />
              <Grid item sm={12}>
                <Grid container>
                  <Grid item>
                    <div className={classes.commentImageDiv}>
                      <img
                        src={userImage}
                        alt="comment"
                        className={classes.commentImage}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={10}>
                    <div className={classes.commentData}>
                      <Typography
                        variant="h5"
                        component={Link}
                        to={`/users/${userHandle}`}
                        color="secondary"
                      >
                        {userHandle}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {dayjs(createdAt).format("HH:mm, MMMM DD YYYY")}
                      </Typography>
                      <hr className={classes.invisibleSeperator} />
                      <Typography variant="body1">{body}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    );
  }
}

Comments.propTypes = {
  commments: PropTypes.array
};

export default withStyles(styles)(Comments);
