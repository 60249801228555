import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteContestant from "./DeleteContestant";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

// Redux
import { connect } from "react-redux";
import { updateContestant } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  runnerContainer: {
    position: "relative",
    width: 300,
    height: 240,
    borderRadius: 10,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    padding: 20,
    margin: 10
  },
  deleteButton: {
    position: "absolute",
    right: 15,
    top: 15
  },
  runnerId: {
    textAlign: "center",
    color: "white"
  },
  runnerName: {
    color: "white"
  },
  time: {
    color: "white",
    margin: "10px auto 10px auto"
  },
  startButton: {
    backgroundColor: "#6bffc1",
    color: "#000",
    height: 80
  },
  stopButton: {
    backgroundColor: "#d1193e",
    color: "#FFF",
    height: 80
  },
  resumeButton: {
    backgroundColor: "#fae76e",
    color: "#000",
    height: 80
  },
  regButton: {
    backgroundColor: "#179ae6",
    color: "#FFF",
    height: 80
  }
});

class Contestant extends Component {
  state = {
    startTime: null,
    elapsedTime: 0,
    finalTime: null,
    finalized: false
  };
  componentWillMount() {
    this.setState({ startTime: this.props.contestant.startTime });
    this.setState({ finalTime: this.props.contestant.finalTime });
    this.setState({ finalized: this.props.contestant.finalized });
    if (
      this.props.contestant.startTime !== null &&
      !this.props.contestant.finalized
    ) {
      this.timeInterval = setInterval(this.timeHandler, 100);
      this.timeRunning = true;
    }
    if (this.props.contestant.finalTime !== null) {
      this.timeRunning = false;
    }
  }
  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }
  startTimer = () => {
    let newStartTime = { startTime: new Date().getTime() };
    this.props.updateContestant(
      this.props.contestant.contestantId,
      newStartTime
    );
    this.setState(newStartTime);
    this.timeRunning = true;
    this.timeInterval = setInterval(this.timeHandler, 100);
  };
  stopTimer = () => {
    if (this.timeRunning) {
      this.timeRunning = false;
      this.timeHandler();
      let newFinalTime = {
        startTime: this.state.startTime,
        finalTime: this.state.elapsedTime
      };
      this.props.updateContestant(
        this.props.contestant.contestantId,
        newFinalTime
      );
      this.setState({ finalTime: this.state.elapsedTime });
    } else {
      this.timeRunning = true;
      let newFinalTime = { startTime: this.state.startTime, finalTime: null };
      this.setState({ finalTime: null });
      this.props.updateContestant(
        this.props.contestant.contestantId,
        newFinalTime
      );
    }
  };
  postTime = () => {
    clearInterval(this.timeInterval);
    let newFinalized = {
      startTime: this.state.startTime,
      finalTime: this.state.finalTime,
      finalized: true
    };
    this.setState(newFinalized);
    this.props.updateContestant(
      this.props.contestant.contestantId,
      newFinalized
    );
  };
  timeHandler = () => {
    var time = new Date().getTime() - this.state.startTime;
    this.setState({ elapsedTime: time });
  };

  timeString = millis => {
    var ms = millis % 1000;
    var s = ((millis - ms) / 1000) % 60;
    var m = ((millis - ms - s * 1000) / 60000) % 60;
    var h = (millis - ms - s * 1000 - m * 60000) / 3600000;

    ms = Math.floor(ms / 100);
    s = s.toString().length === 1 ? "0" + s.toString() : s.toString();
    m = m.toString().length === 1 ? "0" + m.toString() : m.toString();

    return `${h}:${m}:${s}.${ms}`;
  };

  render() {
    const { elapsedTime, startTime, finalTime, finalized } = this.state;
    const {
      contestant: { runnerId, firstName, contestantId },
      UI: { updatingContestant },
      classes
    } = this.props;

    return (
      <Fragment key={runnerId}>
        <Grid item className={classes.runnerContainer} color="secondary">
          <DeleteContestant contestantId={contestantId} />
          <Typography variant="h4" className={classes.runnerId}>
            {runnerId}
          </Typography>
          <Typography variant="body1" className={classes.runnerName}>
            {firstName}
          </Typography>
          <Typography variant="h5" className={classes.time}>
            {finalized
              ? this.timeString(finalTime)
              : finalTime !== null
              ? this.timeString(elapsedTime) +
                " / " +
                this.timeString(finalTime)
              : this.timeString(elapsedTime)}
          </Typography>
          <ButtonGroup variant="contained" color="primary" fullWidth>
            {startTime !== null ? null : (
              <Button onClick={this.startTimer} className={classes.startButton}>
                Start
              </Button>
            )}
            {startTime === null || finalized ? null : (
              <Button
                onClick={this.stopTimer}
                className={
                  finalTime === null ? classes.stopButton : classes.resumeButton
                }
              >
                {finalTime === null ? "Stop" : "Resume"}
              </Button>
            )}
            {finalized || finalTime === null ? null : (
              <Button
                onClick={this.postTime}
                className={classes.regButton}
                disabled={updatingContestant}
              >
                Post
              </Button>
            )}
          </ButtonGroup>
        </Grid>
      </Fragment>
    );
  }
}

Contestant.propTypes = {
  contestant: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { updateContestant }
)(withStyles(styles)(Contestant));
