import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Contestant from "./Contestant";

// MUI
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  ...theme.theme
});

class ContestantList extends Component {
  render() {
    const { contestants } = this.props;

    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {contestants
          .sort((a, b) => b.runnerId - a.runnerId)
          .map((contestant, index) => {
            return <Contestant contestant={contestant} key={index} />;
          })}
      </Grid>
    );
  }
}

ContestantList.propTypes = {
  contestants: PropTypes.array.isRequired
};

export default withStyles(styles)(ContestantList);
