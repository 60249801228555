import {
  SET_EVENTS,
  LOADING_DATA,
  LIKE_EVENT,
  UNLIKE_EVENT,
  DELETE_EVENT,
  SET_ERRORS,
  POST_EVENT,
  UPDATE_EVENT,
  UPDATE_MYEVENT,
  CLEAR_ERRORS,
  LOADING_UI,
  STOP_LOADING_UI,
  UPDATING_CONTESTANT_UI,
  STOP_UPDATING_CONTESTANT_UI,
  SET_EVENT,
  SUBMIT_COMMENT,
  MAKE_EVENT_PUBLIC,
  MAKE_EVENT_NOT_PUBLIC,
  SET_EXPANDED,
  CLEAR_EXPANDED,
  ADD_CLASS,
  ADD_CONTESTANT,
  DELETE_CONTESTANT,
  UPDATE_CONTESTANT,
  UPDATE_MYEVENTS,
  DELETE_MYEVENT,
  INC_RESULTS
} from "../types";

import axios from "axios";

// Get all public events
export const getEvents = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/events")
    .then(res => {
      dispatch({
        type: SET_EVENTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: SET_EVENTS,
        payload: []
      });
    });
};

// Get one event
export const getEvent = (eventId, expand) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/event/${eventId}`)
    .then(res => {
      dispatch({
        type: SET_EVENT,
        payload: res.data
      });
      if (expand) {
        dispatch({ type: SET_EXPANDED, payload: eventId });
      }
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: STOP_LOADING_UI });
    });
};

// Post an event
export const postEvent = newEvent => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/event", newEvent)
    .then(res => {
      dispatch({
        type: POST_EVENT,
        payload: res.data
      });
      dispatch({
        type: UPDATE_MYEVENTS,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

// Update an event
export const updateEvent = (eventId, eventUpdate) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/event/${eventId}/update`, eventUpdate)
    .then(res => {
      dispatch({
        type: UPDATE_EVENT,
        payload: res.data
      });
      dispatch({
        type: UPDATE_MYEVENT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

// Make event public
export const makePublic = eventId => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/event/${eventId}/public`)
    .then(res => {
      dispatch({
        type: MAKE_EVENT_PUBLIC,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: STOP_LOADING_UI });
    });
};

// Make event not public
export const makeNotPublic = eventId => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/event/${eventId}/unpublic`)
    .then(res => {
      dispatch({
        type: MAKE_EVENT_NOT_PUBLIC,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: STOP_LOADING_UI });
    });
};

// Like an event
export const likeEvent = eventId => dispatch => {
  axios
    .get(`/event/${eventId}/like`)
    .then(res => {
      dispatch({
        type: LIKE_EVENT,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

// Unlike an event
export const unlikeEvent = eventId => dispatch => {
  axios
    .get(`/event/${eventId}/unlike`)
    .then(res => {
      dispatch({
        type: UNLIKE_EVENT,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

// Submit a comment
export const submitComment = (eventId, commentData) => dispatch => {
  axios
    .post(`/event/${eventId}/comment`, commentData)
    .then(res => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete an event
export const deleteEvent = eventId => dispatch => {
  axios
    .delete(`/event/${eventId}`)
    .then(() => {
      dispatch({ type: DELETE_EVENT, payload: eventId });
      dispatch({ type: DELETE_MYEVENT, payload: eventId });
    })
    .catch(err => console.log(err));
};

// Get user data
export const getUserData = userHandle => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then(res => {
      dispatch({ type: SET_EVENTS, payload: res.data.events });
    })
    .catch(() => {
      dispatch({
        type: SET_EVENTS,
        payload: null
      });
    });
};

// Add event Class
export const addClass = (eventId, newClass) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/event/${eventId}/addClass`, newClass)
    .then(res => {
      dispatch({
        type: ADD_CLASS,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

// Add Contestant
export const addContestant = (eventId, newContestant) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/event/${eventId}/addContestant`, newContestant)
    .then(res => {
      dispatch({
        type: ADD_CONTESTANT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete Contestant
export const deleteContestant = contestantId => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .delete(`/contestant/${contestantId}`)
    .then(() => {
      dispatch({ type: DELETE_CONTESTANT, payload: contestantId });
      dispatch(clearErrors());
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: STOP_LOADING_UI });
    });
};

// Update Contestant
export const updateContestant = (contestantId, contestantInfo) => dispatch => {
  dispatch({ type: UPDATING_CONTESTANT_UI });
  axios
    .post(`/contestant/${contestantId}`, contestantInfo)
    .then(res => {
      dispatch({ type: UPDATE_CONTESTANT, payload: res.data });
      if (res.data.finalized) {
        dispatch({ type: INC_RESULTS, payload: res.data.eventId });
      }
      dispatch({ type: STOP_UPDATING_CONTESTANT_UI });
    })
    .catch(err => {
      console.log(err);
      dispatch({ type: STOP_UPDATING_CONTESTANT_UI });
    });
};

export const clearErrors = () => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
};

export const clearExpanded = () => dispatch => {
  dispatch({ type: CLEAR_EXPANDED });
};
