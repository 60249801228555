import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
import TipButton from "../../util/TipButton";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import LikeButton from "./LikeButton";
import Comments from "./Comments";
import CommentForm from "./CommentForm";
import Results from "./Results";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Icons
import AccessTime from "@material-ui/icons/AccessTime";
import CalendarToday from "@material-ui/icons/CalendarToday";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import FaceIcon from "@material-ui/icons/Face";
import LocationOn from "@material-ui/icons/LocationOn";
import UnfoldMore from "@material-ui/icons/UnfoldMore";

// Redux
import { connect } from "react-redux";
import { getEvent, clearErrors } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  profileImage: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: 10
  },
  dialogContent: {
    padding: 20
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15
  },
  expandButton: {
    position: "absolute",
    right: 15,
    bottom: 15
  },
  spinnerDiv: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 50
  },
  collapse: {
    padding: 20,
    margin: 20
  },
  details: {
    width: "100%"
  },
  chip: {
    margin: theme.spacing(1)
  }
});

class EventDialog extends Component {
  state = {
    open: false,
    oldPath: "",
    newPath: "",
    expandedComments: false,
    expandedResults: false
  };
  componentDidMount() {
    if (this.props.openDialog) {
      this.handleOpen();
    }
  }
  handleOpen = () => {
    let oldPath = window.location.pathname;

    const { userHandle, eventId } = this.props;
    const newPath = `/users/${userHandle}/event/${eventId}`;

    if (oldPath === newPath) oldPath = `/users/${userHandle}`;

    window.history.pushState(null, null, newPath);

    this.setState({ open: true, oldPath, newPath });
    this.props.getEvent(this.props.eventId, false);
  };
  handleClose = () => {
    window.history.pushState(null, null, this.state.oldPath);
    this.setState({ open: false });
    this.props.clearErrors();
  };
  expandComments = () => {
    if (!this.state.expandedComments) {
      this.setState({ expandedComments: true });

      if (this.state.expandedResults) {
        this.setState({ expandedResults: false });
      }
    } else {
      this.setState({ expandedComments: false });
    }
  };
  expandResults = () => {
    if (!this.state.expandedResults) {
      this.setState({ expandedResults: true });

      if (this.state.expandedComments) {
        this.setState({ expandedComments: false });
      }
    } else {
      this.setState({ expandedResults: false });
    }
  };

  render() {
    const {
      classes,
      eventDetails: {
        eventId,
        title,
        body,
        dateTime,
        location,
        createdAt,
        likeCount,
        commentCount,
        runCount,
        userImage,
        userHandle,
        comments,
        contestants,
        classes: cls
      },
      UI: { loading }
    } = this.props;

    const { expandedComments, expandedResults } = this.state;

    const dialogMarkup = loading ? (
      <DialogContent className={classes.dialogContent} dividers={true}>
        <div className={classes.spinnerDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      </DialogContent>
    ) : (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item sm={2} xs={12}>
              <img
                src={userImage}
                alt="Profile"
                className={classes.profileImage}
              />
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography variant="h5" color="secondary">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Event added at {dayjs(createdAt).format("HH:mm, MMMM DD YYYY")}
              </Typography>
              <hr className={classes.invisibleSeperator} />
              <Typography variant="body1">{body}</Typography>
              <Tooltip title="Date">
                <Chip
                  size="small"
                  icon={<CalendarToday />}
                  label={dayjs(dateTime).format("dddd, DD. MMMM YYYY")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
              <Tooltip title="Time">
                <Chip
                  size="small"
                  icon={<AccessTime />}
                  label={dayjs(dateTime).format("HH:mm")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
              <Tooltip title="Location">
                <Chip
                  size="small"
                  icon={<LocationOn />}
                  label={location}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
              <Tooltip title="Arranged by">
                <Chip
                  size="small"
                  icon={<FaceIcon />}
                  label={userHandle}
                  className={classes.chip}
                  color="primary"
                  component={Link}
                  to={`/users/${userHandle}`}
                />
              </Tooltip>
              <hr className={classes.invisibleSeperator} />
              <Tooltip title="Results - click to view">
                <Chip
                  size="small"
                  icon={<DirectionsRun />}
                  label={runCount + " Results"}
                  className={classes.chip}
                  color="secondary"
                  onClick={this.expandResults}
                />
              </Tooltip>
              <LikeButton eventId={eventId} label={likeCount + " Likes"} />
              <Tooltip title="Comments - click to view">
                <Chip
                  size="small"
                  icon={<ChatIcon />}
                  label={commentCount + " Comments"}
                  className={classes.chip}
                  color="secondary"
                  onClick={this.expandComments}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.details}>
            <Grid item xs={12}>
              <Collapse in={expandedComments} timeout="auto" unmountOnExit>
                <Comments comments={comments} />
                <CommentForm eventId={eventId} />
              </Collapse>
              <Collapse in={expandedResults} timeout="auto" unmountOnExit>
                <Results contestants={contestants} cls={cls} />
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>
      </Fragment>
    );

    return (
      <Fragment>
        <TipButton
          onClick={this.handleOpen}
          tip="Expand event"
          tipClassName={classes.expandButton}
        >
          <UnfoldMore color="secondary" />
        </TipButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth="xl"
          scroll="paper"
          fullWidth
          fullScreen={this.props.width === "sm" || this.props.width === "xs"}
        >
          {dialogMarkup}
          <TipButton
            tip="Close"
            onClick={this.handleClose}
            btnClassName={classes.closeButton}
          >
            <CloseIcon />
          </TipButton>
        </Dialog>
      </Fragment>
    );
  }
}

EventDialog.propTypes = {
  getEvent: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  userHandle: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  eventDetails: state.data.eventDetails,
  UI: state.UI
});

const mapActionsToProps = {
  getEvent,
  clearErrors
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withWidth()(EventDialog)));
