import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LIKE_EVENT,
  UNLIKE_EVENT,
  MARK_NOTIFICATIONS_READ,
  UPDATE_MYEVENTS,
  DELETE_MYEVENT,
  UPDATE_MYEVENT
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
  likes: [],
  notifications: [],
  events: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        ...action.payload
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true
      };
    case LIKE_EVENT:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userHandle: state.credentials.handle,
            eventId: action.payload.eventId
          }
        ]
      };
    case UNLIKE_EVENT:
      return {
        ...state,
        likes: state.likes.filter(
          like => like.eventId !== action.payload.eventId
        )
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach(not => (not.read = true));
      return {
        ...state
      };
    case UPDATE_MYEVENTS:
      return {
        ...state,
        events: [action.payload, ...state.events]
      };
    case UPDATE_MYEVENT:
      let myIndex = state.events.findIndex(
        event => event.eventId === action.payload.eventId
      );
      state.events[myIndex] = action.payload;
      return {
        ...state
      };
    case DELETE_MYEVENT:
      let delIndex = state.events.findIndex(
        event => event.eventId === action.payload
      );
      if (delIndex >= 0) state.events.splice(delIndex, 1);
      return {
        ...state
      };
    default:
      return state;
  }
}
