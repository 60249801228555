import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import DeleteEvent from "./DeleteEvent";
import EventDialog from "./EventDialog";
import LikeButton from "./LikeButton";

// MUI
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

// Icons
import ChatIcon from "@material-ui/icons/Chat";
import CalendarToday from "@material-ui/icons/CalendarToday";
import AccessTime from "@material-ui/icons/AccessTime";
import LocationOn from "@material-ui/icons/LocationOn";

// Redux
import { connect } from "react-redux";
import { makePublic, makeNotPublic } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  card: {
    position: "relative",
    marginBottom: 20
  },
  header: {
    padding: 10
  },
  content: {},
  body: {
    marginLeft: 10
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  chip: {
    margin: theme.spacing(1)
  }
});

class PublicEvent extends Component {
  handleChange = event => {
    if (!event.target.checked)
      this.props.makeNotPublic(this.props.event.eventId);
    else this.props.makePublic(this.props.event.eventId);
  };
  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      event: {
        title,
        body,
        dateTime,
        location,
        createdAt,
        userImage,
        userHandle,
        eventId,
        likeCount,
        commentCount
      },
      user: {
        authenticated,
        credentials: { handle }
      }
    } = this.props;

    const deleteButton =
      authenticated && userHandle === handle ? (
        <DeleteEvent eventId={eventId} />
      ) : null;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Tooltip title={userHandle} placement="top">
              <Avatar
                alt={userHandle}
                src={userImage}
                className={classes.bigAvatar}
                component={Link}
                to={`/users/${userHandle}`}
              />
            </Tooltip>
          }
          action={deleteButton}
          title={title}
          titleTypographyProps={{ variant: "h5", color: "secondary" }}
          subheader={`Added ${dayjs(createdAt).fromNow()}`}
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <Typography variant="body1" className={classes.body}>
            {body}
          </Typography>
          <Grid container>
            <Grid item>
              <Tooltip title="Date">
                <Chip
                  size="small"
                  icon={<CalendarToday />}
                  label={dayjs(dateTime).format("dddd, DD. MMMM YYYY")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Time">
                <Chip
                  size="small"
                  icon={<AccessTime />}
                  label={dayjs(dateTime).format("HH:mm")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Location">
                <Chip
                  size="small"
                  icon={<LocationOn />}
                  label={location}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <LikeButton eventId={eventId} label={likeCount + " Likes"} />
            </Grid>
            <Grid item>
              <Tooltip title="Comments">
                <Chip
                  size="small"
                  icon={<ChatIcon />}
                  label={commentCount + " Comments"}
                  className={classes.chip}
                  color="secondary"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <EventDialog
            eventId={eventId}
            userHandle={userHandle}
            openDialog={this.props.openDialog}
          />
        </CardContent>
      </Card>
    );
  }
}

Event.propTypes = {
  makePublic: PropTypes.func.isRequired,
  makeNotPublic: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool
};

const mapActionsToProps = {
  makePublic,
  makeNotPublic
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PublicEvent));
