import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// MUI
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

// Icons
import SendIcon from "@material-ui/icons/Send";

// Redux
import { connect } from "react-redux";
import { submitComment } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  commentFormRoot: {
    width: "100%",
    justify: "center"
  },
  input: {
    width: "100%",
    margin: 10
  },
  sendButtonContainer: {
    justify: "center",
    margin: "auto"
  },
  iconButton: {
    padding: 10,
    margin: "auto"
  }
});

class CommentForm extends Component {
  state = {
    body: "",
    errors: {}
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ body: "" });
    }
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.submitComment(this.props.eventId, { body: this.state.body });
  };
  render() {
    const { classes, authenticated } = this.props;
    const { errors } = this.state;

    const commentFormMarkup = authenticated ? (
      <Grid item sm={12} style={{ textAlign: "center" }}>
        <Paper className={classes.commentFormRoot}>
          <form onSubmit={this.handleSubmit}>
            <Grid container direction="row" justify="center">
              <Grid item sm={11}>
                <TextField
                  name="body"
                  type="text"
                  label="Comment on event"
                  error={errors.comment ? true : false}
                  helperText={errors.comment}
                  value={this.state.body}
                  onChange={this.handleChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item sm={1} className={classes.sendButtonContainer}>
                <IconButton
                  color="secondary"
                  className={classes.iconButton}
                  type="submit"
                >
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    ) : null;
    return commentFormMarkup;
  }
}

CommentForm.propTypes = {
  submitComment: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  UI: state.UI,
  authenticated: state.user.authenticated
});

export default connect(
  mapStateToProps,
  { submitComment }
)(withStyles(styles)(CommentForm));
