import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

// MUI
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";

// Redux
import { connect } from "react-redux";
import { likeEvent, unlikeEvent } from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  chip: {
    margin: theme.spacing(1)
  }
});

export class LikeButton extends Component {
  likedEvent = () => {
    if (
      this.props.user.likes &&
      this.props.user.likes.find(like => like.eventId === this.props.eventId)
    )
      return true;
    else return false;
  };
  likeEvent = () => {
    this.props.likeEvent(this.props.eventId);
  };
  unlikeEvent = () => {
    this.props.unlikeEvent(this.props.eventId);
  };
  render() {
    const { classes, label } = this.props;
    const { authenticated } = this.props.user;
    const likeButton = !authenticated ? (
      <Link to="/login">
        <Tooltip title="Like">
          <Chip
            size="small"
            icon={<FavoriteBorder />}
            label={label}
            className={classes.chip}
            color="secondary"
          />
        </Tooltip>
      </Link>
    ) : this.likedEvent() ? (
      <Tooltip title="Undo like">
        <Chip
          size="small"
          icon={<FavoriteIcon />}
          label={label}
          className={classes.chip}
          color="secondary"
          onClick={this.unlikeEvent}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Like">
        <Chip
          size="small"
          icon={<FavoriteBorder />}
          label={label}
          className={classes.chip}
          color="secondary"
          onClick={this.likeEvent}
        />
      </Tooltip>
    );
    return likeButton;
  }
}
LikeButton.propTypes = {
  likeEvent: PropTypes.func.isRequired,
  unlikeEvent: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapActionsToProps = {
  likeEvent,
  unlikeEvent
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(LikeButton));
