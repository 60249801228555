// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const UPDATING_CONTESTANT_UI = "UPDATING_CONTESTANT_UI";
export const STOP_UPDATING_CONTESTANT_UI = "STOP_UPDATING_CONTESTANT_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_EXPANDED = "SET_EXPANDED";
export const CLEAR_EXPANDED = "CLEAR_EXPANDED";
// Data reducer types
export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENT = "SET_EVENT";
export const LIKE_EVENT = "LIKE_EVENT";
export const UNLIKE_EVENT = "UNLIKE_EVENT";
export const LOADING_DATA = "LOADING_DATA";
export const DELETE_EVENT = "DELETE_EVENT";
export const POST_EVENT = "POST_EVENT";
export const MAKE_EVENT_PUBLIC = "MAKE_EVENT_PUBLIC";
export const MAKE_EVENT_NOT_PUBLIC = "MAKE_EVENT_NOT_PUBLIC";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const ADD_CLASS = "ADD_CLASS";
export const ADD_CONTESTANT = "ADD_CONTESTANT";
export const DELETE_CONTESTANT = "DELETE_CONTESTANT";
export const UPDATE_CONTESTANT = "UPDATE_CONTESTANT";
export const UPDATE_MYEVENTS = "UPDATE_MYEVENTS";
export const DELETE_MYEVENT = "DELETE_MYEVENT";
export const INC_RESULTS = "INC_RESULTS";
export const DEC_RESULTS = "DEC_RESULTS";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_MYEVENT = "UPDATE_MYEVENT";
