import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import DeleteEvent from "./DeleteEvent";
import LikeButton from "./LikeButton";
import Comments from "./Comments";
import CommentForm from "./CommentForm";
import AddClass from "./AddClass";
import AddContestant from "./AddContestant";
import ContestantList from "./ContestantList";
import Results from "./Results";

// MUI
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers";

// Icons
import ChatIcon from "@material-ui/icons/Chat";
import CalendarToday from "@material-ui/icons/CalendarToday";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import AccessTime from "@material-ui/icons/AccessTime";
import LocationOn from "@material-ui/icons/LocationOn";
import PublicIcon from "@material-ui/icons/Public";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/List";

// Redux
import { connect } from "react-redux";
import {
  makePublic,
  makeNotPublic,
  getEvent,
  clearErrors,
  clearExpanded,
  updateEvent
} from "../../redux/actions/dataActions";

const styles = theme => ({
  ...theme.theme,
  card: {
    position: "relative",
    marginBottom: 20
  },
  header: {
    padding: 10
  },
  content: {
    padding: "0 10px 10px 10px"
  },
  body: {
    marginLeft: 10
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  expansionDetails: {
    padding: 0
  },
  expansionRoot: {
    flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular
  },
  expandedAppbar: {
    zIndex: 1099
  },
  expansion: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  tabContent: {
    margin: 10
  },
  spinnerDiv: {
    margin: "auto",
    textAlign: "center",
    marginTop: 50,
    marginBottom: 50
  },
  contestantsContainer: {
    position: "relative"
  },
  submitButton: {
    position: "relative",
    float: "right",
    marginTop: 10
  },
  progressSpinner: {
    position: "absolute"
  },
  publishButton: {},
  icon: {
    color: "white"
  },
  chip: {
    margin: theme.spacing(1)
  }
});

class MyEvent extends Component {
  state = {
    value: 0,
    title: "",
    body: "",
    dateTime: new Date(),
    location: "",
    published: false,
    errors: {}
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({
        errors: {}
      });
    }
  }
  componentWillUnmount() {
    this.props.clearExpanded();
  }
  handleTabChange = (event, value) => {
    this.setState({ value });
  };
  handleExpandedChange = panelId => (event, isExpanded) => {
    if (isExpanded) {
      this.props.getEvent(panelId, true);
      this.setState({
        title: this.props.event.title,
        body: this.props.event.body,
        dateTime: this.props.event.dateTime,
        location: this.props.event.location,
        published: this.props.event.published
      });
    } else {
      this.props.clearExpanded();
    }
  };
  handleSwitch = event => {
    if (this.state.published)
      this.props.makeNotPublic(this.props.event.eventId);
    else this.props.makePublic(this.props.event.eventId);
    this.setState({ published: !this.state.published });
  };
  handleSettingsChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleDateTimeChange = dateTime => {
    this.setState({ dateTime: dateTime });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.updateEvent(this.props.event.eventId, {
      title: this.state.title,
      body: this.state.body,
      dateTime: this.state.dateTime,
      location: this.state.location
    });
  };
  render() {
    dayjs.extend(relativeTime);
    const {
      classes,
      event: {
        title,
        body,
        dateTime,
        location,
        createdAt,
        userImage,
        userHandle,
        eventId,
        likeCount,
        commentCount,
        runCount
      },
      eventDetails,
      user: {
        authenticated,
        credentials: { handle }
      },
      UI: { loading },
      expanded
    } = this.props;

    const { errors, published } = this.state;

    const settingsMarkup =
      loading || expanded === "" ? (
        <div className={classes.spinnerDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      ) : (
        <Fragment>
          <Typography variant="h6" color="secondary">
            Update event
          </Typography>
          <Grid container>
            <Grid item container sm={12} justify="center" spacing={3}>
              <Grid item>
                <AddClass eventDetails={eventDetails} />
              </Grid>
              <Grid item>
                <Fab
                  color="secondary"
                  className={classes.publishButton}
                  onClick={this.handleSwitch}
                  variant="extended"
                >
                  <PublicIcon className={classes.icon} />
                  {published ? "Unpublish event" : "Publish event"}
                </Fab>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  name="title"
                  value={this.state.title}
                  type="text"
                  label="Event title"
                  placeholder="The name of the event"
                  error={errors.title ? true : false}
                  helperText={errors.title}
                  className={classes.textField}
                  onChange={this.handleSettingsChange}
                  fullWidth
                />
                <TextField
                  name="location"
                  value={this.state.location}
                  type="text"
                  label="Event location"
                  placeholder="The location of the event"
                  error={errors.location ? true : false}
                  helperText={errors.location}
                  className={classes.textField}
                  onChange={this.handleSettingsChange}
                  fullWidth
                />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        ampm={false}
                        format="HH:mm dd/MM/yyyy"
                        margin="normal"
                        name="dateTime"
                        label="Event date and time"
                        error={errors.dateTime ? true : false}
                        helperText={errors.dateTime}
                        value={this.state.dateTime}
                        onChange={this.handleDateTimeChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <TextField
                  name="body"
                  value={this.state.body}
                  type="text"
                  label="Event description"
                  multiline
                  rows="3"
                  placeholder="A descriptive event text"
                  error={errors.body ? true : false}
                  helperText={errors.body}
                  className={classes.textField}
                  onChange={this.handleSettingsChange}
                  fullWidth
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                  disabled={loading}
                >
                  Update
                  {loading && (
                    <CircularProgress
                      size={30}
                      className={classes.progressSpinner}
                    />
                  )}
                </Button>
              </form>
            </Grid>
          </Grid>
        </Fragment>
      );

    const commentsMarkup =
      loading || expanded === "" ? (
        <div className={classes.spinnerDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Comments comments={eventDetails.comments} />
          <CommentForm eventId={eventId} />
        </Grid>
      );

    const contestantsMarkup =
      loading || expanded === "" ? (
        <div className={classes.spinnerDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      ) : (
        <ContestantList contestants={eventDetails.contestants} />
      );

    const resultsMarkup =
      loading || expanded === "" ? (
        <div className={classes.spinnerDiv}>
          <CircularProgress size={200} thickness={2} />
        </div>
      ) : (
        <Results
          contestants={eventDetails.contestants}
          cls={eventDetails.classes}
        />
      );

    const deleteButton =
      authenticated && userHandle === handle ? (
        <DeleteEvent eventId={eventId} />
      ) : null;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Tooltip title={userHandle} placement="top">
              <Avatar
                alt={userHandle}
                src={userImage}
                className={classes.bigAvatar}
                component={Link}
                to={`/users/${userHandle}`}
              />
            </Tooltip>
          }
          action={deleteButton}
          title={title}
          titleTypographyProps={{ variant: "h5", color: "secondary" }}
          subheader={`Added ${dayjs(createdAt).fromNow()}`}
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <Typography variant="body1" className={classes.body}>
            {body}
          </Typography>
          <Grid container>
            <Grid item sm={12}>
              <Tooltip title="Date">
                <Chip
                  size="small"
                  icon={<CalendarToday />}
                  label={dayjs(dateTime).format("dddd, DD. MMMM YYYY")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
              <Tooltip title="Time">
                <Chip
                  size="small"
                  icon={<AccessTime />}
                  label={dayjs(dateTime).format("HH:mm")}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
              <Tooltip title="Location">
                <Chip
                  size="small"
                  icon={<LocationOn />}
                  label={location}
                  className={classes.chip}
                  color="primary"
                />
              </Tooltip>
            </Grid>
            <Grid item sm={12}>
              <Tooltip title="Results">
                <Chip
                  size="small"
                  icon={<DirectionsRun />}
                  label={runCount + " Results"}
                  className={classes.chip}
                  color="secondary"
                />
              </Tooltip>
              <LikeButton eventId={eventId} label={likeCount + " Likes"} />
              <Tooltip title="Comments">
                <Chip
                  size="small"
                  icon={<ChatIcon />}
                  label={commentCount + " Comments"}
                  className={classes.chip}
                  color="secondary"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <ExpansionPanel
          expanded={expanded === eventId}
          onChange={this.handleExpandedChange(eventId)}
          className={classes.expansionPanel}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Event handling</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetails}>
            <div className={classes.expansionRoot}>
              <AppBar
                position="static"
                color="primary"
                className={classes.expandedAppbar}
              >
                <Tabs
                  value={this.state.value}
                  onChange={this.handleTabChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab
                    icon={<PeopleIcon />}
                    label={this.state.value === 0 ? "Contestants" : ""}
                  />
                  <Tab
                    icon={<ListIcon />}
                    label={this.state.value === 1 ? "Results" : ""}
                  />
                  <Tab
                    icon={<ChatIcon />}
                    label={this.state.value === 2 ? "Comments" : ""}
                  />
                  <Tab
                    icon={<SettingsIcon />}
                    label={this.state.value === 3 ? "Settings" : ""}
                  />
                </Tabs>
              </AppBar>
              <Box
                hidden={this.state.value !== 0}
                className={classes.tabContent}
              >
                <Grid
                  container
                  spacing={2}
                  className={classes.contestantsContainer}
                >
                  <AddContestant eventDetails={eventDetails} />
                  {contestantsMarkup}
                </Grid>
              </Box>
              <Box
                hidden={this.state.value !== 1}
                className={classes.tabContent}
              >
                {resultsMarkup}
              </Box>
              <Box
                hidden={this.state.value !== 2}
                className={classes.tabContent}
              >
                {commentsMarkup}
              </Box>
              <Box
                hidden={this.state.value !== 3}
                className={classes.tabContent}
              >
                {settingsMarkup}
              </Box>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    );
  }
}

Event.propTypes = {
  makePublic: PropTypes.func.isRequired,
  makeNotPublic: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  clearExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  eventDetails: PropTypes.object,
  openDialog: PropTypes.bool
};

const mapActionsToProps = {
  makePublic,
  makeNotPublic,
  getEvent,
  updateEvent,
  clearErrors,
  clearExpanded
};

const mapStateToProps = state => ({
  user: state.user,
  eventDetails: state.data.eventDetails,
  expanded: state.UI.expanded,
  UI: state.UI
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(MyEvent));
